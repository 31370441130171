import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { icons } from './assets/icons'

import { Provider } from 'react-redux'

import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/database'
import 'firebase/auth'
import 'firebase/storage'

import { createStore, combineReducers } from 'redux'
import {
  ReactReduxFirebaseProvider,
  firebaseReducer
} from 'react-redux-firebase'

import { createFirestoreInstance, firestoreReducer } from 'redux-firestore'

const firebaseConfig = {
  apiKey: "AIzaSyC5RsWsHFavBPsGtb7XLwH7fGHfkX46BvU",
  authDomain: "stratifylabs.firebaseapp.com",
  databaseURL: "https://stratifylabs.firebaseio.com",
  projectId: "stratifylabs",
  storageBucket: "stratifylabs.appspot.com",
  messagingSenderId: "138951245354",
  appId: "1:138951245354:web:8631f79f1399671f8dcd53"
};

// react-redux-firebase config
const reactReduxFirebaseConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
}

firebase.initializeApp(firebaseConfig)

// Initialize other services on firebase instance
firebase.firestore() // <- needed if using firestore

// firebase.functions() // <- needed if using httpsCallable


const initialState = {
  coreui: {
    sidebarShow: 'responsive',
    asideShow: false,
    darkMode: false
  }
}


const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
}

// Add firebase to reducers
const rootReducer = combineReducers({
  coreui: changeState,
  firebase: firebaseReducer,
  firestore: firestoreReducer // <- needed if using firestore
})

const store = createStore(rootReducer, initialState)

const reactReduxFirebaseProps = {
  firebase,
  config: reactReduxFirebaseConfig,
  dispatch: store.dispatch,
  createFirestoreInstance
}

React.icons = icons

ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...reactReduxFirebaseProps}>
      <App />
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
